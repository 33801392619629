<template>
  <div class="fixed top-0 right-0 left-0 z-30">
    <header id="site_header" class="bg-white border-b border-grey max-h-[100px] py-8 px-8 lg:px-12 xl:px-24 flex items-center justify-between">
      <div>
        <NuxtLink to="/">
         <Logo class="w-40"/>
        </NuxtLink>
      </div>
      <div class="flex">
        <Hamburger
          :is-mobile="screenState.getScreen.isMobile"
          :is-open="screenState.getScreen.isHamburgerMenuOpen"
          @toggle-menu="screenState.toggleMenu()"
        />
        <PageMenuLayout
          v-if="!screenState.getScreen.isMobile"
          menu-location="PRIMARY"
          menu-classes="gap-8 items-center flex"
        />
        <ClientOnly>
          <MyAccountLink/>
        </ClientOnly>
      </div>
    </header>
    <BreadCrumbs v-if="route.path !== '/'"/>
  </div>
</template>
<script setup lang="ts">
import PageMenuLayout from "~/layouts/PageMenuLayout.vue";
import { useScreenState } from "~/stores/screen";
import { onBeforeUnmount, onMounted, watch } from "vue";
import Hamburger from "~/components/global/Navigation/Hamburger.vue";
import BreadCrumbs from "~/components/pages/navigation/BreadCrumbs.vue";
import MyAccountLink from "~/components/pages/navigation/MyAccountLink.vue";

const route = useRoute();
const screenState = useScreenState();

onMounted(async () => {
  window.addEventListener("resize", screenState.checkMobile);
  screenState.checkMobile();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", screenState.checkMobile);
});

watch(
  () => route.path,
  async (newRoute, oldRoute) => {
    if (newRoute !== oldRoute &&
      screenState.getScreen.isHamburgerMenuOpen &&
      screenState.getScreen.isMobile) {
      screenState.toggleMenu();
    }
  }
);

</script>
