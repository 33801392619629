<template>
  <NuxtLink to="/account/dashboard" class="text-secondary ml-6 md:ml-8 font-medium">
    <template v-if="isAuthenticated">My Dashboard</template>
    <template v-else>Sign in</template>
  </NuxtLink>
</template>

<script setup lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
const { isAuthenticated } = useAuth0();

</script>

<style scoped>

</style>
