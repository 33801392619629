<template>
  <div class="py-2 px-8 md:px-12 xl:px-24 bg-white">
    <div class="page-container">
      <nav>
        <ul class="flex flex-nowrap whitespace-nowrap pr-8
      overflow-x-scroll text-xs gap-2">
          <li class="flex gap-2">
            <NuxtLink to="/" class="text-grey-300">Home</NuxtLink>
            <span v-if="breadcrumbTrail.length > 0"> > </span>
          </li>
          <li v-for="(crumb, index) in breadcrumbTrail" :key="index" class="flex gap-2">
            <NuxtLink :to="crumb.url" :class="index === breadcrumbTrail.length - 1 ? 'font-medium text-primary' : 'text-grey-300'">{{ crumb.text }}</NuxtLink>
            <span v-if="index < breadcrumbTrail.length - 1"> > </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
  const route = useRoute();

  const breadcrumbTrail = ref<Array<{text: string, url: string} | []>>([]);

  // Extracting the route hierarchy from the current route
  const breadcrumbs = route.path.split('/').filter((crumb) => crumb);

  const makeTitle = (str: string) => {
    return str
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  breadcrumbTrail.value = breadcrumbs.map((crumb, index) => {
    const path = `/${breadcrumbs.slice(0, index + 1).join('/')}`;

    let label = makeTitle(crumb);

    return {
      text: label,
      url: path,
    };
  });
</script>

<style scoped>

</style>
